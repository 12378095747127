te
<template>
  <div v-if="rendered">
    <div class="row">
      <div class="col-md-12">
        <vnud-card>
          <template v-slot:header>
            <div class="row">
              <div class="col-4">
                <h4 class="card-title">Leads</h4>
              </div>
              <div class="col-4" style="margin: 10px 0px">
                <fg-input>
                  <el-input
                    type="search"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Pesquisar"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </fg-input>
              </div>
              <div class="col-4 pull-right" style="text-align: right">
                <n-button class="black-button" @click="downloadCsv()">
                  <i class="fa fa-cloud-download"></i> Download
                </n-button>
              </div>
            </div>
          </template>

          <div class="table-responsive">
            <el-table stripe style="width: 100%" :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <!-- <el-table-column fixed="right" label="Ações">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <n-button
                      @click="converter(props.row)"
                      class="black-button"
                      size="sm"
                      round
                      icon
                    >
                      <i class="fa fa-check"></i>
                    </n-button>
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <p class="card-category">
                  Mostrando <span class="boldNumbers">{{ from + 1 }}</span> até
                  <span class="boldNumbers">{{ to }}</span> de
                  <span class="boldNumbers">{{ total }}</span> leads.
                </p>
              </div>
              <n-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </n-pagination>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <vnud-card>
          <collapse>
            <collapse-item
              title="Exportar com intervalo de datas"
              id="0"
              active
            >
              <div class="row">
                <div class="col-4">
                  <fg-input>
                    <el-date-picker
                      type="datetime"
                      placeholder="De"
                      v-model="dateTimePickerDe"
                    >
                    </el-date-picker>
                  </fg-input>
                </div>
                <div class="col-4">
                  <fg-input>
                    <el-date-picker
                      type="datetime"
                      placeholder="Até"
                      v-model="dateTimePickerAte"
                    >
                    </el-date-picker>
                  </fg-input>
                </div>
                <div
                  v-if="dateTimePickerDe != '' && dateTimePickerAte != ''"
                  class="col-4"
                  style="text-align: right"
                >
                  <n-button
                    style="margin: 0px !important"
                    class="black-button"
                    @click="downloadCsvDatas()"
                  >
                    <i class="fa fa-cloud-download"></i> Download
                  </n-button>
                </div>
              </div>
            </collapse-item>
          </collapse>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Collapse,
    CollapseItem,
    Pagination as NPagination,
  } from "@/components";
  import api from "@/api/index";
  import { json2excel } from "js2excel";

  import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElTimeSelect,
    ElDatePicker,
  } from "element-plus";
  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
      NPagination,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      Collapse,
      CollapseItem,
      [ElDatePicker.name]: ElDatePicker,
      [ElTimeSelect.name]: ElTimeSelect,
    },
    data() {
      return {
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["nome", "email"],
        tableColumns: [
          {
            prop: "nome",
            label: "Nome",
          },
          {
            prop: "email",
            label: "Email",
          },
          {
            prop: "dt_created",
            label: "Data de Entrada",
          },
        ],
        searchedData: [],
        fuseSearch: null,
        rendered: false,
        leads: {
          conversoes: 0,
          numero: 0,
          mes: 0,
          organicas: 0,
        },
        dataTable: [],
        dateTimePickerDe: "",
        dateTimePickerAte: "",
      };
    },
    methods: {
      async downloadCsv() {
        var data = this.dataTable;

        // data.forEach((object) => {
        //   delete object["questionario"];
        //   delete object["ficheiro_orcamento"];
        //   delete object["ficheiro_cliente"];
        //   delete object["id_clickup"];
        //   delete object["dt_created"];
        // });
        try {
          json2excel({
            data,
            name: "Leads",
            formateDate: "yyyy-mm-dd",
          });
        } catch (e) {
          alert("export error");
        }
      },
      isBefore(fromDateStr, toDateStr) {
        var fromDate = new Date(fromDateStr);
        var toDate = new Date(toDateStr);

        return fromDate.getTime() < toDate.getTime();
      },
      async downloadCsvDatas() {
        var data = this.dataTable;
        var dataDe = this.dateTimePickerDe;
        var dataAte = this.dateTimePickerAte;
        if (!this.isBefore(dataDe, dataAte)) {
          return;
        }
        dataDe = new Date(dataDe);
        dataAte = new Date(dataAte);

        data = data.filter((obj) => {
          const time = new Date(obj.dt_created);
          return time >= dataDe && time <= dataAte;
        });

        // data.forEach((object) => {
        //   delete object["questionario"];
        //   delete object["ficheiro_orcamento"];
        //   delete object["ficheiro_cliente"];
        //   delete object["id_clickup"];
        //   delete object["dt_created"];
        // });
        try {
          json2excel({
            data,
            name: "Leads",
            formateDate: "yyyy-mm-dd",
          });
        } catch (e) {
          alert("export error");
        }
      },
      async converter(array) {
        if (array.convertido == "0") {
          array.convertido = "1";
        } else {
          array.convertido = "0";
        }
      },
      async inicio() {
        let json = {
          cliente: JSON.parse(localStorage.getItem("lc_Dashboard_Login"))
            .cliente,
        };
        let res = await api.listarLeadsRgpd(json);
        if (res) {
          this.dataTable = res.sort((a, b) => {
            return a.id > b.id ? -1 : 1;
          });
          for (let i = 0; i < this.dataTable.length; i++) {
            this.dataTable[i].data_convertida = this.dataTable[
              i
            ].dt_created.slice(0, 10);
          }
          let data = new Date();
          let mesCorrente = data.getMonth() + 1;
          let esteMes = res.filter((obj) => {
            let desteMes = parseInt(obj.dt_created.substr(5, 2));
            return desteMes === mesCorrente;
          });
        }
        this.rendered = true;
      },
    },
    mounted() {
      this.inicio();
    },
    computed: {
      pagedData() {
        return this.dataTable.slice(this.from, this.to);
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData() {
        if (!this.searchQuery) {
          return this.pagedData;
        }
        let result = this.dataTable.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString();
            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
              isIncluded = true;
            }
          }
          return isIncluded;
        });
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.dataTable.length;
      },
    },
  };
</script>
<style></style>
