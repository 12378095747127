<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :title="titulo">
      <template v-slot:links>
        <user-menu :title="nome"></user-menu>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'now-ui-icons design_app',
            path: '/dashboard',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Dashboard Incumprimento',
            icon: 'now-ui-icons design_app',
            path: '/dashboard-incumprimento',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Taxas Website',
            icon: 'now-ui-icons business_globe',
            path: '/dashboard-taxas',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'RGPD - Leads',
            icon: 'now-ui-icons users_single-02',
            path: '/dashboard-rgpd',
          }"
        >
        </sidebar-item>

        <li class="" @click="logout()">
          <a href="#" class="nav-link"
            ><i class="now-ui-icons sport_user-run"></i>
            <p>Sair</p></a
          >
        </li>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  // import PerfectScrollbar from "perfect-scrollbar";
  // import "perfect-scrollbar/css/perfect-scrollbar.css";

  // function hasElement(className) {
  //   return document.getElementsByClassName(className).length > 0;
  // }
  //
  // function initScrollbar(className) {
  //   if (hasElement(className)) {
  //     new PerfectScrollbar(`.${className}`);
  //   } else {
  //     // try to init it later in case this component is loaded async
  //     setTimeout(() => {
  //       initScrollbar(className);
  //     }, 100);
  //   }
  // }

  import TopNavbar from "./TopNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import UserMenu from "./extra/UserMenu.vue";

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      UserMenu,
    },
    data() {
      return {
        titulo: "",
        nome: "",
      };
    },
    methods: {
      logout() {
        localStorage.removeItem("lc_Dashboard_Login");
        this.$router.push("/login");
      },
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
    },
    mounted() {
      this.titulo = this.capitalize(
        JSON.parse(localStorage.getItem("lc_Dashboard_Login")).cliente
      );
      this.nome = this.capitalize(
        JSON.parse(localStorage.getItem("lc_Dashboard_Login")).nome
      );
    },
  };
</script>
<style lang="scss"></style>
